/**
 *  String transformation helpers. Can be imported in any component.
 */
export const isArray = function (arr) {
    /**
     * Checks if given param is an array
     * @param  {[any]} arr
     * @return {[boolean]}
     */
    return Array.isArray(arr);
};

export const isObject = function (obj) {
    /**
     * Checks if given param is an object
     * @param  {[any]} obj
     * @return {[boolean]}
     */
    return obj === Object(obj) && !isArray(obj) && typeof obj !== "function";
};

export const strToCamelCase = (str: string) => {
    /**
     * Converts a string from snake_case or kebab-case to camelCase while removing underscores or hyphens
     * before numbers.
     *
     * @param {string} str - The input string in snake_case or kebab-case format.
     * @returns {string} - The converted string in camelCase format.
     */
    return str
        .replace(/[_-]([a-z])/g, (_, match) => match.toUpperCase())
        .replace(/[_-]/g, "");
};

export const strToSnakeCase = (str: string) => {
    /**
     * Uses regular expressions to convert a string's casing (accepts either camel, pascal or kebab case)
     * to snake case. It adds underscore before numbers at the end of the string
     * @param  {[string]} str
     * @return {[string]}
     */
    str = str
        .replace(/([a-z])([A-Z])/g, "$1_$2") // Handle camelCase and PascalCase
        .replace(/-/g, "_"); // Handle kebab-case

    str = str.replace(/(\d+$)/, "_$1"); // Add underscore before numbers at the end of the string

    return str.toLowerCase();
};

export const keysToCamelCase = (obj: any) => {
    /**
     * Tests if the function param is an object. If yes, it loops through all of
     * its keys, converts each key to camel case, and recursively call the
     * keysToCamel function on the value of each item in the object for nested objects.
     * @param  {[any]} obj
     * @return {[Object]}
     */
    if (isObject(obj)) {
        const n = {};

        Object.keys(obj).forEach((key) => {
            n[strToCamelCase(key)] = keysToCamelCase(obj[key]);
        });

        return n;
    } else if (isArray(obj)) {
        return obj.map((i) => {
            return keysToCamelCase(i);
        });
    }

    return obj;
};

export const keysToSnakeCase = (obj: any) => {
    /**
     * Tests if the function param is an object. If yes, it loops through all of
     * its keys, converts each key to snake case, and recursively call the
     * keysSnakeCase function on the value of each item in the object for nested objects.
     * @param  {[any]} obj
     * @return {[Object]}
     */
    if (isObject(obj)) {
        const n = {};

        Object.keys(obj).forEach((key) => {
            n[strToSnakeCase(key)] = keysToSnakeCase(obj[key]);
        });

        return n;
    } else if (isArray(obj)) {
        return obj.map((i) => {
            return keysToSnakeCase(i);
        });
    }

    return obj;
};

export const camelCaseToRegularText = (str: string) => {
    /**
     * This function takes a string in camelCase or PascalCase and converts it into regular text by
     * inserting spaces between words wherever an uppercase letter follows a lowercase letter.
     * @param {[string]} str
     * @returns {[string]}
     */
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const snakeToRegularText = (snakeStr: string) => {
    /**
     * This function takes a string in snake_case and converts it into regular text by
     * inserting spaces between words by splitting the strings based on underscores.
     * @param {[string]} snakeStr
     * @returns {[string]}
     */
    let words = snakeStr.split("_");
    words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(" ");
};

export const kebabCaseToRegularText = (str: string) => {
    /**
     * This function takes a string in kebab-case and converts it into regular text by
     * inserting spaces between words wherever an uppercase letter follows a lowercase letter.
     * @param {[string]} str
     * @returns {[string]}
     */
    const words = str.split("-");

    // Capitalize the first letter of each word and join them with a space
    const spacedText = words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    return spacedText;
};

export const concatenateStrings = (
    separator: string,
    ...strings: (string | null)[]
): string => {
    /**
     * Concatenates any number of given strings with the given separator.
     * It trims and filters out empty and null strings
     * @param {[string]} separator
     * @param {[...string | null]} - strings to separate
     * @returns {[string]}
     */
    const filteredStrings = strings
        .map((s) => (s !== null ? s.trim() : ""))
        .filter((s) => s !== "");

    const result = filteredStrings.join(separator);

    return result;
};

export const pluralize = (
    count: number,
    singular: string,
    plural: string = "",
): string => {
    /** Checks if a word should be plural base on the count
     * If one argument is passed, an 's' will be added
     * If two arguments are passed, the second argument will be used as the plural form
     * @param {[number]} count
     * @param {[string]} singular
     * @param {[string]} plural
     * @returns {[string]}
     */
    if (plural === "") {
        plural = `${singular}s`;
    }
    return count === 1 ? singular : plural;
};
