import { RouteRecordRaw } from "vue-router";
import authGuard from "@/router/authGuard";
import { Permissions } from "@/constants/access";

const adminRoutes: Array<RouteRecordRaw> = [
    {
        path: "/admin",
        name: "Admin",
        component: () => import("@/views/admin/AdminLayout.vue"),
        beforeEnter: authGuard,
        meta: {
            requiredPermissions: [Permissions.ESENDA_ADMIN],
        },
        // all the child routes will be rendered inside AdminLayout's <router-view>
        // and will be available under the /admin/ namespace
        children: [
            {
                path: "create-organisation",
                name: "CreateOrganisation",
                component: () =>
                    import("@/views/admin/tools/CreateOrganisation.vue"),
                beforeEnter: authGuard,
                meta: {
                    requiredPermissions: [Permissions.ESENDA_ADMIN],
                },
            },
        ],
    },
];

export default adminRoutes.map((route) => {
    return { ...route };
});
