/**
 * The method below uses the auth integration to return an access token
 * which will be used when making calls to the API.
 */
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { usePiniaStore } from "@/stores/store";
import { redirectToRoute } from "@/router";
import { showAlert } from "@/methods/errorHandling";

export const getToken = async () => {
    const pStore = usePiniaStore();

    try {
        const session = await fetchAuthSession();
        const token = session.tokens?.accessToken;
        if (!token) {
            //** Sign out user if we cannot get a token */
            pStore.setIsAuthenticated(false);
            await signOut({ global: true });
            showAlert("alerts.expiredSession");
            redirectToRoute({ name: "LoginEsenda" });
            return null;
        }
        return token;
    } catch (error) {
        //** Sign out user if we cannot get a token */
        pStore.setIsAuthenticated(false);
        await signOut({ global: true });
        showAlert("alerts.expiredSession");
        redirectToRoute({ name: "LoginEsenda" });
        return null;
    }
};
