import { getCurrentUser } from "aws-amplify/auth";
import { usePiniaStore } from "@/stores/store";

/**
 * The ts-ignore comment below was added because on build the compiler will throw this
 * typescript error: TS6133: 'from' is declared but its value is never read.
 * But the router plugin requires all 3 parameters to be passed in.
 *  */
// @ts-ignore
async function authGuard(to, from, next) {
    const pStore = usePiniaStore();

    /** If the user tries to navigate to /, skip the guard check */
    if (to.path === "/") {
        next();
        return;
    }

    try {
        /** Attempt to get the authenticated user */
        const { userId } = await getCurrentUser();

        if (userId) {
            /** User is authenticated, proceed to the route */
            pStore.setIsAuthenticated(true);
            next();
        } else {
            pStore.setIsAuthenticated(false);
            next("/unauthorised-access");
        }
    } catch (error) {
        pStore.setIsAuthenticated(false);
        next("/");
    }
}

export default authGuard;
