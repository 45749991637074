<template>
    <div></div>
    <v-spacer></v-spacer>
    <div class="user-container mr-3">
        <!-- user logo -->
        <v-img class="user-logo" :src="userPicture"></v-img>
        <!-- user details -->
        <div class="user-details-container">
            <div
                data-cy="userName"
                class="user-name body-medium white-text mt-2"
            >
                {{ userName }}
            </div>
        </div>
        <!-- settings button -->
        <div class="settings-container">
            <icon-button
                data-cy="userMenuButton"
                btn-style="default-btn medium-icon-btn transparent-btn"
                :icon-src="getImageUrl('icons/20x20/ic20-log-out.svg')"
                @run-method="handleLogout()"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { usePiniaStore } from "@/stores/store";
import { signOut } from "aws-amplify/auth";

import iconButton from "@/components/buttons/iconButton.vue";
import { getImageUrl } from "@/methods/iconHelpers";
import { redirectToRoute } from "@/router";

const pStore = usePiniaStore();

// get user details
const userPicture = computed(() => "");
const userName = computed(() => pStore.getUserEmail);

// logs user out
const handleLogout = async () => {
    await signOut({ global: true });
    pStore.setIsAuthenticated(false);
    redirectToRoute({ name: "LoginEsenda" });
};
</script>
