import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import adminRoutes from "./routes/adminRoutes";
import schoolRoutes from "./routes/schoolRoutes";
import authRoutes from "./routes/authRoutes";
import registrationRoutes from "./routes/registrationRoutes";
import { usePiniaStore } from "../stores/store";
import { checkIfArrayElementsMatch } from "../methods/pathRedirection";
import { Permissions } from "@/constants/access";
import { BACSInstructionTypeKebabCase } from "@/constants/bacs";
import { captureEvent } from "@/plugins/posthog";

// adds the admin and school routes to the router
const routes: Array<RouteRecordRaw> = [
    ...authRoutes,
    ...adminRoutes,
    ...schoolRoutes,
    ...registrationRoutes,
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

/**
 * The ts-ignore comment below was added because on build the compiler will throw this
 * typescript error: TS6133: 'from' is declared but its value is never read.
 * But the router plugin requires all 3 parameters to be passed in.
 *  */
// @ts-ignore
router.beforeEach((to, from, next) => {
    const pStore = usePiniaStore();

    if (from.path !== to.path) {
        captureEvent("$pageleave", { category: "navigation" });
    }

    // user permissions
    const storedUserPermissions = pStore.getPermissions;
    // meta route properties
    const isAuthenticated = pStore.getIsAuthenticated;

    const requiredPermissions: string[] =
        (to.meta.requiredPermissions as string[]) || [];
    const requiresValidBacsInstructionType = to.matched.some(
        (x) => x.meta.checkInstructionType,
    );

    const currentUserPermissions = storedUserPermissions
        ? storedUserPermissions
        : [];
    const adminToolsPermissions = [Permissions.ESENDA_ADMIN];
    const basePermissions = [Permissions.BASE];
    const hasAccessToAdminTools = checkIfArrayElementsMatch(
        adminToolsPermissions,
        currentUserPermissions,
    );
    const hasBaseAccess = checkIfArrayElementsMatch(
        basePermissions,
        currentUserPermissions,
    );

    /**
     * Redirects to 404 if the route requiresValidBacsInstructionType and the
     * id instructionType is not a valid instruction type
     */
    if (requiresValidBacsInstructionType) {
        if (
            !Object.values(BACSInstructionTypeKebabCase).includes(
                to.params.instructionType as string,
            )
        ) {
            next("/not-found");
            return;
        }
    }

    /** Handle authenticated users navigating to root */
    if (isAuthenticated && to.path === "/") {
        if (hasAccessToAdminTools) {
            /** Redirecting to admin tools creation page */
            next("/admin/create-organisation");
            return;
        }
        if (hasBaseAccess) {
            /** Redirecting to school overview */
            next("/school/overview");
            return;
        }

        /** No permissions access - redirecting to company registration **/
        next("/registration/company-details");
        return;
    }

    /** Check if the user permissions list includes the route's required permissions */
    if (requiredPermissions.length > 0) {
        const hasRequiredPermissions = requiredPermissions.every((permission) =>
            currentUserPermissions.includes(permission),
        );

        if (!hasRequiredPermissions) {
            next("/unauthorised-access");
            return;
        }
    }

    /** If all conditions are met, allow navigation */
    next();
});

router.afterEach((to) => {
    captureEvent("$pageview", { path: to.path, category: "navigation" });
});

export const redirectTo404 = () => {
    router.push({ name: "404" });
};

export const redirectToAccessDenied = () => {
    router.push({ name: "UnauthorisedAccess" });
};

export const changeRoute = (path: string) => {
    router.push(path).catch((err) => {
        console.log("Route error: " + err);
    });
};

export const redirectToFile = (id: string) => {
    const path = `/school/bacs/files?id=${id}`;
    router.push(path).catch((err) => {
        console.log("Route error: " + err);
    });
};

export const redirectToRoute = (route: any) => {
    const existingState = { ...history.state };

    router.replace({
        ...route,
        state: existingState,
    });
};

export const navigateBack = (step: number) => {
    /**
     * Navigate back
     * @param {number} step - indicates by how many steps to go backward in the history stack
     */
    router.go(step);
};

export default router;
